/* eslint-disable complexity */
import { TranslateService } from '@ngx-translate/core';
import { camelCase, forOwn, transform } from 'lodash-es';
import moment, { Moment } from 'moment';
import { BehaviorSubject, firstValueFrom, Observable, timer } from 'rxjs';
import { filter, map, startWith, takeUntil, skip, first } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, HostBinding, inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { PaymentCardsUtils } from '@bp/shared/domains/payment-cards';
import { FiatCurrency } from '@bp/shared/models/currencies';
import { ResponseStatusCode } from '@bp/shared/models/core';
import { Validators } from '@bp/shared/features/validation/models';
import { Dictionary, ensureFormGroupConfig, Typify } from '@bp/shared/typings';
import { Countries } from '@bp/shared/models/countries';
import { uuid } from '@bp/shared/utilities/core';

import { FADE_IN, SLIDE } from '@bp/frontend/animations';
import { HostNotifierService } from '@bp/frontend/domains/checkout/services';
import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { CheckoutValidators } from '@bp/frontend/domains/checkout/validation';
import { TelemetryService } from '@bp/frontend/services/telemetry';
import { BpError } from '@bp/frontend/models/core';
import { ApmPaymentOptionSubType, PaymentOptionType } from '@bp/frontend/models/business';
import { EnvironmentService } from '@bp/frontend/services/environment';

import { IPaymentMethod, CheckoutSession, EmbeddedData, IBank, IRequestApmDeposit, CryptoTransaction, Transaction, IApiTransaction, CheckoutSessionValidatablePropertyNames, HAS_SELF_SUBMIT_FORM_KEY, IPersonalIdType, ApmEtfTransaction } from '@bp/checkout-frontend/models';
import { PaymentApiService, AppService, PaymentOptionInstancesManager } from '@bp/checkout-frontend/providers';

type DefaultInputField = CheckoutSessionValidatablePropertyNames & keyof IPaymentForm;

const additionalInputFields = <const>[
	'birthDate',
	'gender',
	'personalId',
	'bankAccountName',
	'bankAgency',
	'iban',
	'sortCode',
	'accountNumber',
	'expiryDate',
	'addressStreet',
	'addressNumber',
	'addressDistrict',
	'addressComplement',
	'otpCode',
];

@Component({
	selector: 'bp-apm-page',
	templateUrl: './apm-page.component.html',
	styleUrls: [ '../card/card-page.component.scss', './apm-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE, FADE_IN ],
})
export class ApmPageComponent extends Destroyable implements OnInit {

	protected readonly _paymentOptionInstancesManager = inject(PaymentOptionInstancesManager);

	protected readonly _appService = inject(AppService);

	private readonly __formBuilder = inject(FormBuilder);

	private readonly __paymentsApiService = inject(PaymentApiService);

	private readonly __activatedRoute = inject(ActivatedRoute);

	private readonly __translateService = inject(TranslateService);

	private readonly __environment = inject(EnvironmentService);

	private readonly __hostNotifier = inject(HostNotifierService);

	@HostBinding('class.checkout-lg')
	protected get _isCheckoutLarge(): boolean {
		return this._shouldShowDefaultField('phone') && this._shouldShowDefaultField('stateCode');
	}

	protected get _session(): CheckoutSession {
		return this._appService.session!;
	}

	protected get _embeddedData(): EmbeddedData {
		return this._appService.embedded!;
	}

	protected get _isGateToPayProvider(): boolean {
		return this._paymentMethod.provider === 'gatetopay';
	}

	protected _paymentMethod!: IPaymentMethod;

	protected _form: FormGroup;

	protected readonly _isSubmitting$ = new BehaviorSubject(false);

	protected _globalError?: string | null;

	protected readonly _textFieldMaxLength = 255;

	protected _fiatCurrencies: FiatCurrency[] = [];

	protected _currency!: FiatCurrency;

	protected _countries = Countries.listWithWorldwide;

	protected _bankCode?: string;

	protected _bankPaymentType?: string;

	protected _bank?: IBank;

	protected get _controls(): Typify<IPaymentForm, FormControl | undefined> {
		return <any> this._form.controls;
	}

	protected _startDate = moment([ 1990, 1, 1 ]);

	protected _outOfAgeDate = moment().subtract(18, 'years');

	protected _genders$: Observable<{ value: IRequestApmDeposit['gender']; display: string }[]> = this.__translateService.onLangChange
		.pipe(
			startWith(null),
			map(() => [
				{ value: 'female', display: <string> this.__translateService.instant('gender.female') },
				{ value: 'male', display: <string> this.__translateService.instant('gender.male') },
			]),
		);

	protected _apmResponseImg?: string;

	protected _subtype!: ApmPaymentOptionSubType;

	@HostBinding('class.instant-submit')
	protected _shouldSubmitOnInit: boolean;

	protected get _isPspsFingerprintsCollectionRequired(): boolean {
		return !!this._paymentMethod.ddc_htmls;
	}

	protected _pspsFingerprints?: Dictionary<string>;

	protected _allFingerprintsCollected$ = new BehaviorSubject<boolean>(false);

	protected _pspBadge$ = new BehaviorSubject<string | null>(null);

	protected get _cannotSubmit(): boolean {
		return (!this._session.validateInputsOnFocusOut && this._form.invalid) || this._form.pending;
	}

	protected _hasPspPaymentOptionsDropdown = false;

	protected _hasPspPaymentOptionTypesDropdown = false;

	private readonly __inputFields: DefaultInputField[] = [
		'address', 'city', 'zipCode', 'phone', 'email', 'firstName', 'lastName',
	];

	private __wasOnceInvalidMap: Record<string, boolean> = {};

	private __forcedSubmit = false;

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	private get __notificationContext() {
		return {
			type: this._paymentMethod.typeInSnakeCase,
			provider: this._paymentMethod.provider,
		};
	}

	constructor() {
		super();

		this._isSubmitting$
			.pipe(
				skip(1),
				takeUntilDestroyed(this),
			)
			.subscribe(isSubmitting => void this.__hostNotifier.processingPayment({
				value: isSubmitting,
				...this.__notificationContext,
			}));

		this.__activatedRoute.params
			.pipe(takeUntilDestroyed(this))
			.subscribe(({ provider, bankCode, subtype, bankPaymentType, currencyCode }) => {

				this._subtype = ApmPaymentOptionSubType.parseStrict(subtype);

				this._paymentMethod = this._session.paymentMethods
					.find(v => [ PaymentOptionType.apm, PaymentOptionType.banks ].includes(v.type) && v.provider === provider && v.subtype === this._subtype)!;

				this.__forcedSubmit = this._paymentMethod.auto_deposit;

				this._fiatCurrencies = (this._paymentMethod.currencies ?? []).map(v => new FiatCurrency(v));

				this._currency = new FiatCurrency(currencyCode);

				if (bankCode) {
					this._bank = this._paymentMethod.banks!.find(v => v.bank_code === bankCode);

					this._currency = this._bank!.currency;

					this._bankCode = <string>bankCode;
				}

				this.__forcedSubmit ||= this._paymentMethod.subtype.isEft && !!this._bankCode;

				this._hasPspPaymentOptionsDropdown = this._session.showPspPaymentOptionsAsDropdown && !!this._paymentMethod.banks;

				if (this._hasPspPaymentOptionsDropdown) {
					this._hasPspPaymentOptionTypesDropdown = !!this._paymentMethod.banks_payment_types;

					this._currency = this._paymentMethod.banks![0].currency;
				}

				if (bankPaymentType)
					this._bankPaymentType = <string>bankPaymentType;
			});

		if (this._session.isPayWithCheckout)
			this._currency = this._session.currency;

		this.__hostNotifier.paymentMethodOpen(this.__notificationContext);

		if (this._session.country.states)
			this.__inputFields.push('stateCode');

		this._form = this.__formBuilder.group(
			ensureFormGroupConfig<IPaymentForm>({
				...(this._paymentMethod.fields
					? transform(
						this._paymentMethod.fields,
						(config, fieldMetadata) => (config[fieldMetadata.name] = [
							null,
							{ validators: fieldMetadata.required ? Validators.required : null },
						]),
					)
					: {}),

				...transform(
					this.__inputFields.filter(v => !this.__isHiddenField(v)),
					(config, key) => (config[key] = [
						this._session[key].value,
						{ validators: this._session[key].validator },
					]),
				),

				...(this._session.amountLock
					? {}
					: { amount: [ this._paymentMethod.amount ]}
				),

				...(this._session.country.states
					? {
						state: [
							this._session.stateCode.valid ? this._session.country.states.find(v => this._session.stateCode.value === v.code)!.name : '',
							{
								validators: [
									Validators.required,
									CheckoutValidators.stateByName(this._session.country),
								],
							},
						],
					}
					: {}
				),

				countryDialCode: [
					this._session.phoneCountryDialCode,
					{ validators: Validators.required },
				],

				...transform(
					additionalInputFields.filter(field => this._isAdditional(field) && !this.__isHiddenField(field)),
					(config, key) => (config[key] = [
						key === 'birthDate' ? this._session.birthDate ?? null : null,
						{ validators: this._isAdditionalRequired(key) ? Validators.required : null },
					]),
				),

				...(this._isAdditional('expiryDate')
					? {
						expiryDate: [
							'',
							{ validators: Validators.required },
						],
					}
					: {}
				),
				...(this._isAdditional('personalId') && !!this._paymentMethod.personal_id_types
					? {
						personalIdType: [
							this._paymentMethod.personal_id_types[0],
							{ validators: Validators.required },
						],
					}
					: {}),
				...(this._hasPspPaymentOptionsDropdown
					? {
						bank: [
							null,
							{ validators: Validators.required },
						],
					}
					: {}),
				...(this._hasPspPaymentOptionTypesDropdown
					? {
						bankPaymentType: [
							null,
							{ validators: Validators.required },
						],
					}
					: {}
				),
			}),
			{
				updateOn: this._session.validateInputsOnFocusOut ? 'blur' : 'change',
			},
		);

		const embedValidationsAreValid = !this.__inputFields
			.filter(inputField => !this.__isHiddenField(inputField))
			.some(inputField => this._session[inputField].invalid);

		this._shouldSubmitOnInit = embedValidationsAreValid && this._form.valid && this._session.amountLock
			&& (this._appService.isStandalone || !this._paymentMethod.open_in_new_window || this._appService.showPaymentMethodsPage) || this.__forcedSubmit;

		if (this._shouldSubmitOnInit) {
			this._paymentOptionInstancesManager.activeInstance?.markAsSkippedForm();

			setTimeout(async () => this.submit(), 100); // after content rendered to keep natural flow of the events
		}

		this.__reflectEmbedValidationStateOnToControls();

		if (this._paymentMethod.has_badge) {
			this.__paymentsApiService
				.getPspBadge(this._paymentMethod.provider)
				.pipe(takeUntilDestroyed(this))
				.subscribe(badge => void this._pspBadge$.next(badge));
		}

		if (this._paymentMethod.psp_badge)
			this._pspBadge$.next(this._paymentMethod.psp_badge);

		this.__whenFormDirtyNotifyHost();

		this.__updateTelemetryUserIdOnControlChanges();
	}

	ngOnInit(): void {
		this._controls.state
			?.valueChanges
			.pipe(
				filter(() => this._controls.state!.valid),
				map(stateName => this._session.country.states!.find(it => it.name === stateName)),
				takeUntilDestroyed(this),
			)
			.subscribe(state => void this._controls.stateCode!.setValue(state!.code));

	}

	private __updateTelemetryUserIdOnControlChanges(): void {
		this._controls.email?.valueChanges
			.pipe(
				filter(() => this._controls.email!.valid),
				takeUntilDestroyed(this),
			)
			.subscribe((email: string) => void this._appService.telemetryIdentifyUser(email));
	}

	protected _markAsDirtyAllControls(): void {
		forOwn(
			this._controls,
			control => {
				if (control!.pristine) {
					void control!.markAsDirty();

					void control!.updateValueAndValidity(); // invoke changes
				}
			},
		);
	}

	protected _wasOnceInvalid(key: DefaultInputField): boolean {
		if (this.__wasOnceInvalidMap[key])
			return true;

		if (this._session[key].invalid) {
			this.__wasOnceInvalidMap[key] = true;

			return true;
		}

		if (this._controls[key]?.invalid) {
			this.__wasOnceInvalidMap[key] = true;

			return true;
		}

		return false;
	}

	protected _shouldShowDefaultField(key: DefaultInputField): boolean {
		if (this.__isHiddenField(key))
			return false;

		if (this._session.alwaysVisibleInputsForProviders[key]?.includes(this._paymentMethod.provider))
			return true;

		return this._wasOnceInvalid(key);
	}

	protected _isAdditional(fieldName: typeof additionalInputFields[number]): boolean {
		return this._isAdditionalOptional(fieldName) || this._isAdditionalRequired(fieldName);
	}

	protected _isAdditionalRequired(fieldName: typeof additionalInputFields[number]): boolean {
		return this._paymentMethod.additional_required_fields.includes(fieldName);
	}

	protected _isAdditionalOptional(fieldName: typeof additionalInputFields[number]): boolean {
		return this._paymentMethod.additional_optional_fields.includes(fieldName);
	}

	protected _getAmount(): number {
		return this._session.amountLock
			? this._paymentOptionInstancesManager.activeInstance?.amount ?? this._paymentMethod.displayAmount
			: Number(this._controls.amount!.value);
	}

	protected _castToBank(bank: unknown): bank is IBank {
		if ('bank_code' in (<IBank>bank))
			return true;

		throw new Error('Bank is not a bank');
	}

	private __isHiddenField(fieldName: keyof IPaymentForm): boolean {
		return this._paymentMethod.skip_required_fields.includes(<string>fieldName);
	}

	private __getControlValue<TKey extends keyof IPaymentForm>(key: TKey): IPaymentForm[TKey] | undefined {
		return this._controls[key]?.value;
	}

	private __reflectEmbedValidationStateOnToControls(): void {
		this.__inputFields
			.filter(inputField => !this.__isHiddenField(inputField) && this._session[inputField].value && this._session[inputField].invalid)
			.forEach(inputField => void this._controls[inputField]!.markAsDirty());
	}

	private __getAddress(): string | undefined {
		const address = this.__getControlValue('address');

		return this._session.isEquitiJordanAndUAE
			? address?.slice(0, 50)
			: address;
	}

	async submit(): Promise<void> {
		if (this._isSubmitting$.value)
			return;

		if (this._form.invalid && !this.__forcedSubmit) {
			this._markAsDirtyAllControls();

			return;
		}

		this._globalError = null;

		this._isSubmitting$.next(true);

		const paymentRequestBody = this.__buildPaymentRequestBody();

		this._appService.storePaymentRequestBody(paymentRequestBody);

		if (this._isPspsFingerprintsCollectionRequired && !this._allFingerprintsCollected$.value) {
			TelemetryService.log('Waiting for all fingerprints to be collected');

			await firstValueFrom(
				timer(950) // cannot be bigger safari won't open a new tab without confirm popup
					.pipe(takeUntil(
						this._allFingerprintsCollected$.pipe(filter(isCollected => isCollected)),
					)),
				{ defaultValue: null },
			);

			TelemetryService.log('All fingerprints collected');
		}

		if (this._paymentMethod.subtype.isEft && this._bankCode) {
			void this.__continuePayment(this._appService.transaction!.id, {
				currency: this._currency.code,
				type: this._paymentMethod.typeInSnakeCase,
				subtype: this._paymentMethod.subtype.name,
				provider: this._paymentMethod.provider,
				// eslint-disable-next-line @typescript-eslint/naming-convention
				bank_code: this._bankCode,
			});
		} else if (this._appService.isEmbedded && this._paymentMethod.open_in_new_window) {
			this._appService.paymentProcessingPageUrl = await this._appService.buildUrlWithEmbedParamsAndDepositRequest(
				'deposit-processing',
				paymentRequestBody,
				{ encryptPayload: false },
			);

			TelemetryService.log(
				'Opened a new tab',
				this.__environment.isNotProduction
					? this._appService.paymentProcessingPageUrl
					: null,
			);

			this._appService.openTab = window.open(this._appService.paymentProcessingPageUrl, '_blank');

			this._appService.hasDoneDeposit();

			this._isSubmitting$.next(false);

			this._appService.navigate([ '/status/card-proceed-on-open-tab' ]);
		} else
			void this.__makePayment(paymentRequestBody);
	}

	private async __continuePayment(transactionId: string, requestBody: IRequestApmDeposit): Promise<void> {
		try {
			const result = await firstValueFrom(this.__paymentsApiService.continueProcessing(transactionId, requestBody));

			this.__onPaymentResponse(requestBody, result);
		} catch (error: unknown) {
			if (error instanceof BpError)
				this.__onPaymentError(error);
			else
				throw error;
		}
	}

	private async __makePayment(requestBody: IRequestApmDeposit): Promise<void> {
		try {
			const result = await firstValueFrom(this.__paymentsApiService.depositApm(requestBody));

			this.__onPaymentResponse(requestBody, result);
		} catch (error: unknown) {
			if (error instanceof BpError)
				this.__onPaymentError(error);
			else
				throw error;
		}
	}

	private __onPaymentResponse(
		requestBody: IRequestApmDeposit,
		transaction: ApmEtfTransaction | CryptoTransaction | Transaction,
	): void {
		this._session.isRegularLikeCheckout && this._appService.removeAlertBeforeUnload();

		this._isSubmitting$.next(false);

		this.__hostNotifier.requestPaymentSuccess(this.__notificationContext);

		this._appService.transaction = transaction;

		this._appService.paymentMethod = this._paymentMethod;

		this._appService.userEmail = this.__getControlValue('email');

		if (this._session.isPayWithCheckout && transaction.isPending)
			this._paymentOptionInstancesManager.markAsPending();

		if (transaction instanceof Transaction && transaction.wire_transfer_details) {
			this._appService.navigate([ '/payments/apm-wire-transfer-details' ]);

			return;
		}

		if ((this._session.isHighlowQoneco || this._session.isHighlowXoro) && transaction.html) {
			transaction.html = transaction.html
				.replace('min-height: 400px', 'min-height: 330px')
				.replace('padding: 2rem 1rem', 'padding: 0');
		}

		if (transaction instanceof CryptoTransaction)
			this._appService.navigate([ '/payments/crypto-qr' ]);
		else if (transaction instanceof ApmEtfTransaction && !requestBody.bank_code) {
			this._appService.setBanksToMethod(this._paymentMethod, transaction.banks);

			this._appService.navigateToPaymentOption(this._paymentMethod);
		} else if (transaction.isApproved) {
			if (this._session.isPayWithCheckout)
				this._paymentOptionInstancesManager.successContinue(transaction);
			else
				this._appService.navigate([ '/status/card-success' ]);
		} else if (transaction.html) {
			if (this._appService.isEmbedded || transaction.html.includes(HAS_SELF_SUBMIT_FORM_KEY)) {
				if (transaction.html.startsWith('data')) {
					this._apmResponseImg = transaction.html;

					return;
				}

				this._appService.navigateToApmIframe({
					htmlOrLink: transaction.html,
					provider: this._paymentMethod.provider,
				});
			} else {
				TelemetryService.log('Redirected to the trx html content');

				this._appService.navigatePageTo3dParty(transaction.html);
			}
		} else {
			this._appService.navigate([
				transaction.isPending ? '/status/pending' : '/error',
			]);
		}

	}

	private __onPaymentError(error: BpError<IApiTransaction>): void {
		this._appService.setError(error);

		this._isSubmitting$.next(false);

		this.__hostNotifier.requestPaymentError(this.__notificationContext);

		if (error.status === ResponseStatusCode.BadRequest) {
			let hasFieldError = false;

			for (const { message, field } of error.messages.filter(errorMessage => errorMessage.field)) {
				const control = this.__getControlForErrorField(<keyof IRequestApmDeposit>field);

				if (control) {
					control.setErrors({ server: message });

					control.markAsTouched();

					hasFieldError = true;
				}
			}

			if (!hasFieldError)
				this._globalError = 'The entered information is invalid';

			return;
		}

		if (error.payload && 'status' in error.payload)
			this._appService.transaction = new Transaction(error.payload);

		if (this._session.isPayWithCheckout)
			this._paymentOptionInstancesManager.handleDeclinedTransaction();
		else {
			this._appService.navigate([
				error.status === ResponseStatusCode.TransactionDeclined ? '/status/card-declined' : 'error',
			]);
		}
	}

	private __buildPaymentRequestBody(): IRequestApmDeposit {
		const { month, year } = this.__getControlValue('expiryDate')
			? PaymentCardsUtils.parseExpireDateString(this.__getControlValue('expiryDate'))
			: { month: undefined, year: undefined };

		const phone = this.__getControlValue('phone');
		const countryDialCode = this.__getControlValue('countryDialCode');
		const phoneWithoutDialCode = phone?.replace(countryDialCode!, '');

		return {
			/* eslint-disable @typescript-eslint/naming-convention */
			type: this._paymentMethod.typeInSnakeCase,
			provider: this._paymentMethod.provider || this._paymentMethod.brands?.[0],
			address: this.__getAddress(),
			address_street: this.__getControlValue('addressStreet'),
			address_number: this.__getControlValue('addressNumber'),
			address_district: this.__getControlValue('addressDistrict'),
			address_complement: this.__getControlValue('addressComplement'),
			currency: this._currency.code,
			first_name: this.__getControlValue('firstName'),
			last_name: this.__getControlValue('lastName'),
			city: this.__getControlValue('city'),
			zip_code: this.__getControlValue('zipCode'),
			state: this.__getControlValue('stateCode'),
			country: this._session.countryCode.value,
			country_code: countryDialCode,
			phone: phoneWithoutDialCode,
			phone_country_dial_code: countryDialCode,
			email: this.__getControlValue('email'),

			amount: this._getAmount(),
			bank_code: this._bankCode ?? this.__getControlValue('bank')?.bank_code,
			bank_payment_type: this._bankPaymentType ?? this.__getControlValue('bankPaymentType'),
			gender: this.__getControlValue('gender'),
			birth_date: this.__getControlValue('birthDate')?.unix(),
			personal_id: this.__getControlValue('personalId'),
			personal_id_type: this.__getControlValue('personalIdType')?.name,
			bank_account_name: this.__getControlValue('bankAccountName'),
			bank_agency: this.__getControlValue('bankAgency'),
			iban: this.__getControlValue('iban'),
			sort_code: this.__getControlValue('sortCode'),
			account_number: this.__getControlValue('accountNumber'),
			otp_code: this.__getControlValue('otpCode'),

			affiliate_id: this._session.affiliateId,
			tracking_id: this._session.trackingId,
			ip: this._session.ip,
			amount_lock: this._session.amountLock,
			currency_lock: this._session.currencyLock,

			open_in_new_window: !!this._paymentMethod.open_in_new_window,
			subtype: this._subtype.name,
			additional_data: this._appService.browserDetails,
			ddc: this._pspsFingerprints,

			expire_month: month,
			expire_year: year,

			payment_method_instance_id: this._paymentOptionInstancesManager.activeInstance?.id,
			payment_id: uuid(),
			/* eslint-enable @typescript-eslint/naming-convention */

			...this._paymentMethod.fields
				? transform(
					this._paymentMethod.fields,
					(config, fieldMetadata) => (config[fieldMetadata.name] = this.__getControlValue(fieldMetadata.name)),
				)
				: {},
		};
	}

	private __getControlForErrorField(field: keyof IRequestApmDeposit): FormControl | null {
		let controlName: keyof IPaymentForm;

		switch (field) {
			case 'state':
				controlName = 'stateCode';
				break;

			case 'expire_year':

			case 'expire_month':
				controlName = 'expiryDate';
				break;

			default:
				controlName = <keyof IPaymentForm>camelCase(<string>field);
		}

		return this._controls[controlName] ?? null;
	}

	private __whenFormDirtyNotifyHost(): void {
		this._form.valueChanges
			.pipe(
				first(() => this._form.dirty),
				takeUntilDestroyed(this),
			)
			.subscribe(() => void this.__hostNotifier.paymentMethodFormDirty(this.__notificationContext));
	}
}

interface IPaymentForm {
	address?: string;
	addressStreet?: string;
	addressNumber?: string;
	addressDistrict?: string;
	addressComplement?: string;
	city?: string;
	state?: string;
	stateCode?: string;
	zipCode?: string;
	country?: string;
	countryCode?: string;
	countryDialCode?: string;
	phone?: string;
	email?: string;
	firstName?: string;
	lastName?: string;
	amount: number;
	birthDate?: Moment;
	gender?: IRequestApmDeposit['gender'];
	personalId?: string;
	personalIdType?: IPersonalIdType;
	bankAccountName?: string;
	bankAgency?: string;
	bank?: IBank;
	bankPaymentType?: string;
	iban?: string;
	sortCode?: string;
	accountNumber?: string;
	otpCode?: string;
	expiryDate?: string;
	[key: string]: unknown;
}
