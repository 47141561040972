/* eslint-disable complexity */
import { BehaviorSubject, combineLatest, of, merge } from 'rxjs';
import { catchError, distinctUntilChanged, filter, first, map, mapTo, startWith, switchMap, mergeWith, pairwise } from 'rxjs/operators';
import { isString } from 'lodash-es';

import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, inject } from '@angular/core';
import { MatLegacyFormFieldDefaultOptions, MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { NavigationEnd, NavigationError, ResolveStart, Router } from '@angular/router';

import { isInstanceOf } from '@bp/shared/utilities/core';

import { HostNotifierService, LanguagesService } from '@bp/frontend/domains/checkout/services';
import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { TelemetryService } from '@bp/frontend/services/telemetry';
import { EnvironmentService } from '@bp/frontend/services/environment';
import { HttpConfigService } from '@bp/frontend/services/http';
import { FADE_IN, FADE_OUT } from '@bp/frontend/animations';
import { whenNetworkIsOfflineFor$ } from '@bp/frontend/rxjs';
import { FORM_FIELD_DEFAULT_OPTIONS, IFormFieldDefaultOptions } from '@bp/frontend/components/core';
import { BpError } from '@bp/frontend/models/core';

import { AppService } from '@bp/checkout-frontend/providers';
import { EmbeddedData, IS_MPI } from '@bp/checkout-frontend/models';

import { BidiRootDirective } from '../../directives';
import { CoreModule } from '../../core.module';

@Component({
	selector: 'bp-root',
	standalone: true,
	imports: [ CoreModule ],
	styleUrls: [ './root.component.scss' ],
	templateUrl: './root.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		FADE_IN,
		FADE_OUT,
	],
	hostDirectives: [ BidiRootDirective ],
	providers: [
		{
			provide: MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS,
			useFactory(): MatLegacyFormFieldDefaultOptions {
				const appService = inject(AppService);

				return {
					get appearance() {
						return appService.getAppearance();
					},
					get floatLabel() {
						return appService.getFloatLabelType();
					},
					get hideRequiredMarker() {
						return appService.getHideRequiredMarker();
					},
				};
			},
		},
		{
			provide: FORM_FIELD_DEFAULT_OPTIONS,
			useFactory(): IFormFieldDefaultOptions {
				const appService = inject(AppService);

				return {
					get appearance() {
						return appService.getAppearance();
					},
					get floatLabel() {
						return appService.getFloatLabelType();
					},
					get hideRequiredMarker() {
						return appService.getHideRequiredMarker();
					},
					hideClearButton: true,
				};
			},
		},
	],
})
export class RootComponent extends Destroyable {

	protected readonly _appService = inject(AppService);

	protected readonly _hostNotifier = inject(HostNotifierService);

	private readonly __router = inject(Router);

	private readonly __httpConfigService = inject(HttpConfigService);

	private readonly __environment = inject(EnvironmentService);

	private readonly __languagesService = inject(LanguagesService);

	private readonly __$host = <HTMLElement>inject(ElementRef).nativeElement;

	protected get _embedded(): EmbeddedData | null {
		return this._appService.embedded;
	}

	@HostBinding('class.hideUI')
	protected get _hideUI(): boolean {
		return !!this._embedded?.hideUi;
	}

	@HostBinding('class.show')
	protected get _showApp(): boolean {
		return this._appService.isBlox || !this._isShowGlobalSpinner$.value;
	}

	protected _isShowGlobalSpinner$ = new BehaviorSubject(true);

	protected _networkIsLongOffline$ = whenNetworkIsOfflineFor$(10 * 1000);

	private readonly __defaultFontUrls = [ 'https://google-fonts.bridgerpay.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;600;700&family=Roboto+Mono:wght@300;400;500;600' ];

	constructor() {
		super();

		this.__setRefererHeader();

		this.__setRumState();

		this.__setupGlobalSpinner();

		this.__handleNavigationErrors();

		this.__applyCustomizations();

		if (!this._appService.isBlox)
			this.__onNavigationEndBlockHistoryMutation();

		this.__onNavigationEndSendLogTelemetry();
	}

	/**
	 * Sentry cannot observe location change since we don't change location in checkout, we have only virtual
	 * navigation. So we need to set the navigation end log manually.
	 */
	private __onNavigationEndSendLogTelemetry(): void {
		this.__router.events
			.pipe(
				filter(isInstanceOf(NavigationEnd)),
				takeUntilDestroyed(this),
			)
			.subscribe(event => void TelemetryService.log(`Navigated to: ${ decodeURIComponent(event.urlAfterRedirects) }`));
	}

	/**
	 * We block history mutation on the checkout to not affect the natural navigation flow of the host page
	 */
	private __onNavigationEndBlockHistoryMutation(): void {
		this.__router.events
			.pipe(
				first(isInstanceOf(NavigationEnd)),
				takeUntilDestroyed(this),
			)
			.subscribe(() => void history.replaceState(null, '', ''));
	}

	private __handleNavigationErrors(): void {
		this.__router.events
			.pipe(
				filter(isInstanceOf(NavigationError)),
				takeUntilDestroyed(this),
			)
			.subscribe(({ error }) => {
				if (error instanceof Error)
					TelemetryService.log(error.message);

				if (isString(error))
					TelemetryService.log(error);

				if (error instanceof BpError && [ 'session has expired', 'session is closed' ].includes(error.message.toLowerCase()))
					this._appService.setError('The session is closed or expired!\n Please start over!');
				else
					this._appService.setError(error);

				this._appService.navigate([ '/error' ]);
			});
	}

	private __applyCustomizations(): void {
		merge(
			this._appService.embedded$,
			this._appService.session$,
		)
			.pipe(
				map(config => config.theme),
				distinctUntilChanged(),
				takeUntilDestroyed(this),
				startWith(null),
				pairwise(),
			)
			.subscribe(([ oldTheme, newTheme ]) => {
				if (oldTheme)
					this.__removeDocumentClass(oldTheme.cssClass);

				if (newTheme)
					this.__addDocumentClass(newTheme.cssClass);
			});

		this._appService.embedded$
			.pipe(takeUntilDestroyed(this))
			.subscribe(embedded => {
				let customTheme = null;
				let customFontUrls: string[] | null = null;

				switch (true) {
					case embedded.isEquitiJordanAndUAE:
						customTheme = 'equity';
						break;

					case embedded.isVantageFX:
						customTheme = 'vantagefx';
						break;

					case embedded.isSecurePharma:
						customTheme = 'secure-pharma';
						break;

					case embedded.isSimplexLeonardo:
						customTheme = 'simplex-leonardo';
						break;

					case embedded.isHighlow:
						customTheme = 'highlow';

						customFontUrls = [ 'https://google-fonts.bridgerpay.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Roboto+Mono:wght@300;400;500;600&display=swap' ];
						break;

					case embedded.isHighlowV2:
						customTheme = 'highlow-v2';

						customFontUrls = [
							'https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/css/yakuhanjp-noto.min.css',
							'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Roboto+Mono:wght@300;400;500;600&display=swap',
							'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700&display=swap',
						];
						break;

					case embedded.isHighlowQoneco:
						customTheme = 'qoneco';

						customFontUrls = [ 'https://google-fonts.bridgerpay.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Roboto+Mono:wght@300;400;500;600&display=swap' ];
						break;

					case embedded.isHighlowXoro:
						customTheme = 'xoro';

						customFontUrls = [ 'https://google-fonts.bridgerpay.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Roboto+Mono:wght@300;400;500;600&display=swap' ];
						break;

					case embedded.isEtihad:
						customTheme = 'etihad';
						break;

					case embedded.isDavidShields:
						customTheme = 'david-shields';

						customFontUrls = [ 'https://google-fonts.bridgerpay.com/css2?family=Open+Sans:wght@400;500;600;700&family=Roboto+Mono:wght@300;400;500;600&display=swap' ];
						break;

					case embedded.isFortrade:
						customTheme = 'fortrade';
						break;

					case embedded.isAdvanceMarket:
						customTheme = 'advance-market';
						break;

					case embedded.isJamesAllen:
						customTheme = 'james-allen';

						customFontUrls = [ 'https://google-fonts.bridgerpay.com/css2?family=Nunito+Sans:wght@400;700&family=Roboto+Mono:wght@300;400;500;600&display=swap' ];
						break;

					case embedded.isJamesAllenBluennile:
						customTheme = 'james-allen-bluenile';

						customFontUrls = [ 'https://google-fonts.bridgerpay.com/css2?family=Gantari:wght@400;600;700&family=Roboto+Mono:wght@300;400;500;600&display=swap' ];
						break;

					case embedded.isCentury:
						customTheme = 'century';
						break;

					case embedded.isExinity:
						customTheme = 'exinity';
						break;

					case embedded.isTradeQuo:
						customTheme = 'trade-quo';
						break;

					case embedded.isFundedPeaks:
						customTheme = 'funded-peaks';
						break;

					default:
						break;
				}

				if (customTheme)
					this.__addDocumentClass(customTheme);

				this._appService.loadFonts(customFontUrls ?? this.__defaultFontUrls);
			});

		this._appService.session$
			.pipe(
				takeUntilDestroyed(this),
			)
			.subscribe(session => {
				if (session.orderSummary)
					this.__addDocumentClass('has-order-summary');

				if (session.isPaymentCardTokenCheckout)
					this.__addDocumentClass('payment-card-token-checkout');

				if (this._appService.isMerchantAdmin)
					this.__addDocumentClass('merchant-admin');

			});

		this._appService.session$
			.pipe(
				map(session => !!session.isPayWithCheckout),
				distinctUntilChanged(),
				takeUntilDestroyed(this),
			)
			.subscribe(isPayWithCheckout => {
				document.documentElement.classList.toggle('paywith-checkout', isPayWithCheckout);
			});

		this._appService.isStandalone && this.__addDocumentClass('standalone');

		this._appService.isNestedInsideAnotherCheckout && this.__addDocumentClass('nested-inside-another-checkout');

		this._appService.isBlox && this.__addDocumentClass('blox');

		IS_MPI && this.__addDocumentClass('mpi');

		this.__environment.isLocal && this.__addDocumentClass('localhost');

		this.__environment.isNotProduction && this.__addDocumentClass('non-production');
	}

	private __addDocumentClass(...klass: string[]): void {
		document.documentElement.classList.add(...klass);
	}

	private __removeDocumentClass(...klass: string[]): void {
		document.documentElement.classList.remove(...klass);
	}

	private __setupGlobalSpinner(): void {
		const isRouteResolving$ = this.__router.events
			.pipe(
				filter(event => event instanceof ResolveStart || event instanceof NavigationEnd || event instanceof NavigationError),
				map(event => event instanceof ResolveStart),
			);

		this._appService.error$
			.pipe(takeUntilDestroyed(this))
			.subscribe(() => void this._appService.loadFonts(this.__defaultFontUrls));

		combineLatest([
			isRouteResolving$,
			this._appService.onceFontsLoaded(),
			this._appService.session$.pipe(
				switchMap(() => this.__languagesService.loading$),
				first(loading => !loading),
				mergeWith(this._appService.error$.pipe(mapTo(true))),
			),
			this._appService.appReady$,
		])
			.pipe(
				map(([ isRouteResolving ]) => isRouteResolving),
				catchError((error: unknown) => {
					this._appService.setError(<any>error);

					this._appService.navigate([ '/error' ]);

					return of(false);
				}),
				first(isShowGlobalSpinner => !isShowGlobalSpinner),
				takeUntilDestroyed(this),
			)
			.subscribe(() => {
				void this._isShowGlobalSpinner$.next(false);

				// for some reason host binding doesn't kick in when checkout nested in each other
				this.__$host.classList.add('show');

				if (!this._appService.session?.checkoutType.isBlox)
					void this._hostNotifier.contentRendered();
			});
	}

	private __setRefererHeader(): void {
		const referer = this.__$host.getAttribute('referer');

		this._appService.referer = referer;

		TelemetryService.log('Referrer', referer);

		referer && this.__httpConfigService.setHttpHeader('x-referer', referer);
	}

	private __setRumState(): void {
		const rumAttributeValue = this.__$host.getAttribute('rum');

		if (!rumAttributeValue)
			return;

		const checkoutKey = rumAttributeValue === 'true' ? null : rumAttributeValue;

		this._appService.setRumState(true, checkoutKey);
	}
}
