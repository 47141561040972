
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { map } from 'rxjs/operators';
import { upperFirst } from 'lodash-es';
import { Observable } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { isURL } from '@bp/shared/utilities/core';
import { FiatCurrencyCode } from '@bp/shared/models/currencies';

import { retriableRequestOnError, CF_WORKER_PATH_SEGMENT } from '@bp/frontend/services/http';
import { EnvironmentService } from '@bp/frontend/services/environment';

import {
	IApiTransaction, Transaction, IRequestCardDeposit, IRequestApmDeposit, IRequestCryptoDeposit,
	CryptoTransaction, IApiCryptoTransaction, RequestDeposit, IBank, IApmEtfTransaction, ApmEtfTransaction
} from '@bp/checkout-frontend/models';
import { patchBackendGeneratedPspHtml } from '@bp/checkout-frontend/utilities';

@Injectable({
	providedIn: 'root',
})
export class PaymentApiService {

	private readonly __http = inject(HttpClient);

	private readonly __environment = inject(EnvironmentService);

	private readonly __transactionFactory = (dto: IApiTransaction): Transaction => {
		const transaction = new Transaction(dto);

		if (transaction.html && !isURL(transaction.html))
			transaction.html &&= patchBackendGeneratedPspHtml(transaction.html, this.__environment);

		return transaction;
	};

	validateCardBin(bin: string, currency: FiatCurrencyCode) {
		return this.__http
			.post<IApiTransaction>(
				'v2/validate/bin',
				{
					bin,
					currency,
				},
				{
					context: retriableRequestOnError(),
				},
			);
	}

	depositCreditCard(request: IRequestCardDeposit | RequestDeposit) {
		return this.__http
			.post<IApiTransaction>(
				'v2/deposit/credit-card',
				request,
			)
			.pipe(map(dto => this.__transactionFactory(dto)));
	}

	continueProcessing(transactionId: string, request: IRequestCardDeposit | RequestDeposit) {
		return this.__http
			.post<IApiTransaction>(
				`v2/deposit/continue-transaction/${ transactionId }`,
				request,
				{
					context: retriableRequestOnError(),
				},
			)
			.pipe(map(dto => this.__transactionFactory(dto)));
	}

	depositApm(request: IRequestApmDeposit | RequestDeposit) {
		return this.__http
			.post<IApiCryptoTransaction | IApiTransaction | IApmEtfTransaction>(
				`v2/deposit/apm/${ upperFirst(request.provider) }`,
				request,
			)
			.pipe(map(dto => {
				if ('crypto' in dto)
					return new CryptoTransaction(dto);

				if ('banks' in dto)
					return new ApmEtfTransaction(dto);

				return this.__transactionFactory(dto);
			}));
	}

	depositCrypto(request: IRequestCryptoDeposit | RequestDeposit) {
		return this.__http
			.post<IApiCryptoTransaction>(
				`v2/deposit/crypto/${ request.provider }`,
				request,
			)
			.pipe(map(v => new CryptoTransaction(v)));
	}

	transaction(transactionId: string) {
		return this.__http
			.get<IApiTransaction>(
				`v2/transaction/${ transactionId }`,
			)
			.pipe(map(dto => this.__transactionFactory(dto)));
	}

	checkPspTransactionStatus(transactionId: string) {
		return this.__http
			.get<IApiTransaction>(
				`v2/check-psp-transaction-status/${ transactionId }`,
			)
			.pipe(map(dto => this.__transactionFactory(dto)));
	}

	transactionBySessionId(sessionId: string) {
		return this.__http
			.get<IApiTransaction>(
				`v2/transaction/${ sessionId }`,
			)
			.pipe(map(dto => this.__transactionFactory(dto)));
	}

	getBanksInfo(provider: string) {
		return this.__http
			.get<IBank[]>(`v2/apm/${ provider }/banks-info`);
	}

	getPspBadge(provider: string) {
		return this.__http
			.get<string>(`v2/psps/${ provider }/badge`);
	}

	storeEncryptedPaymentToken(id: string, details: string) {
		return this.__http
			.post(
				`${ CF_WORKER_PATH_SEGMENT }/payment-processing-details/${ id }`,
				details,
				{
					context: retriableRequestOnError(),
				},
			);
	}

	fetchEncryptedPaymentToken(id: string): Observable<string | null> {
		return this.__http
			.get(
				`${ CF_WORKER_PATH_SEGMENT }/payment-processing-details/${ id }`,
				{
					responseType: 'text',
				},
			)
			.pipe(map(response => response === 'null' || response === '' ? null : response));
	}
}
